<!--  -->
<template>
    <div class="bod">
    <Breadcrumb style="margin-top: 20px;margin-left: 163px;margin-bottom: 20px;color: rgb(110, 114, 117);">
        <BreadcrumbItem to="/">首页</BreadcrumbItem>
        <BreadcrumbItem to="/message">相关资讯</BreadcrumbItem>
        <BreadcrumbItem>详情</BreadcrumbItem>
    </Breadcrumb>    
      <div class="flex">
        <div style="width: 1000px;" class="biaoti">
            【区创投项目】“促进就业，宝妈创业”线下宣传招募活动
        </div>
      </div>
      <div class="flex">
      <div class="xgleft3"></div>
        <div class="xgleft4">就业创业</div>
        <div class="xgleft1">发布时间：&nbsp&nbsp2023.05.17</div>
      </div>
      <div class="flex">
        <div class="wenben1">
            让更多妈妈了解朝九晚四“促进就业，宝妈创业”巾帼妈妈灵活创业互助项目，让有意愿灵活创业增收的妈妈有渠道实现自己的想法，助力妈妈步入社会，提升自我。5月16日，朝九晚四巾帼创客中心工作人员在新东党建文化广场开展宣传活动。
        </div>
      </div>
      <div class="flex">
        <div class="page3tu1"></div>
      </div>
      <div class="flex">
        <div style="margin-bottom: 50px;" class="wenben2">        该项目是由黄埔区第四届公益创投资助，主要目的为建立一支宝妈互助队伍，让宝妈们抱团取暖，能在照顾家庭之余，可以朝九晚四用招聘服务来灵活创业。现场工作人员为妈妈们详细介绍并说明项目合作形式、服务方式，获得部分妈妈认可。</div>
      </div>
      <div class="flex">
        <div class="page3tu2"></div>
      </div>
      <div class="flex">
        <div style="margin-left: 450px;" class="wenben3">                如果您是一名宝妈，您也想加入，享受项目相关培训或灵活招聘创业服务，可扫码报名。</div>
      </div>
     
      <div class="flex">
        <hr class="hr1"/>
      </div>
      <div style="margin-left: 163px;float: left;">
        <div class="next">上一篇</div>
        <div class="nextkuang">
          <div class="xgleft1">2023.06.01</div>
          <router-link to="/page2" class="text1">巾帼妈妈灵活创业外展就业招聘服务活动</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <router-link to="/page2" class="text2">近日，朝九晚四为了让巾帼妈妈了解招聘，提升巾帼妈妈的招聘能力，中心链接招聘会资源让妈妈开展外展就业招聘服务，希望妈妈能从实践中提升技能，拓展个人招聘资源，促进就业岗位对接实现灵活增收......</router-link>
          <router-link to="/page2"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
        </div>
      </div>
      <div style="margin-left: 75px;float: left;">
        <div class="next">下一篇</div>
        <div class="nextkuang">
          <div class="xgleft1">2023.02.07</div>
          <router-link to="/page4" class="text1">区妇联王主席视察朝九晚四，推进妇女灵活就业工作</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">各界关怀</div>
          <router-link to="/page4" class="text2">2023年2月7日上午，黄埔区妇联主席王晓红带队莅临朝九晚四创客中心，指导朝九晚四妇女灵活就业工作。希望2023年能大力推进落实工作计划，开发朝九晚四灵活就业岗位，培育百个育儿妈妈，以就业促创业，促进妇女群体灵活增收......</router-link>
          <router-link to="/page4"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
        </div>
      </div>
      <div class="clean"></div>
    </div>
  
  </template>
  
  <script>
  export default {
    data() {
      return {
  
      }
    },
    methods: {
  
    },
    created() {
  
    }
  }
  </script>
  
  <style lang="less" scoped>
  @import "./page.less";
  </style>
  